<template>
  <v-card>
    <v-card-title class="tocs_gray_1">
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$tc('global.buscar')"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      >
      </v-text-field>
      <v-spacer class="d-none d-sm-flex"></v-spacer>

      <v-menu offset-y :close-on-click="closeOnClick">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="tocs_gray_2" class="" dark v-bind="attrs" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            {{ $tc("global.opcao", 2) }}
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <!--- Lista Preços -->
        <v-list class="pa-0" dense>
          <v-divider></v-divider>

          <v-list-item v-if="PTipoVisualizar" @click="dialogTipo = true">
            <v-list-item-icon>
              <v-icon left>mdi-clipboard-list-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $tc("global.tipo", 2) }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item
            v-if="PCategoriaVisualizar"
            @click="dialogCategoria = true"
          >
            <v-list-item-icon>
              <v-icon left>mdi-format-list-bulleted-type</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $tc("global.categoria", 2) }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-menu>
      <DialogCategorias
        v-if="dialogCategoria"
        :dialogCategoria.sync="dialogCategoria"
      />
      <DialogTipos v-if="dialogTipo" :dialogTipo.sync="dialogTipo" />

      <v-btn
        v-if="PFornecedorAdicionar"
        :to="{ path: '/fornecedores/adicionar' }"
        color="success"
        class="ml-2 mt-4 mt-sm-0"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $tc("global.add") }}
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="fornecedores"
        :options.sync="options"
        :server-items-length="totalFornecedores"
        :items-per-page="15"
        class="data-tables"
        :class="{ 'data-tables__row-click': PFornecedorVisualizar }"
        @click:row="goToFornecedor"
      >
        <template v-slot:item.status="{ item }">
          <span size="28" v-if="item.status === 0">inativo</span>
          <span v-if="item.status === 1"> ativo </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getFornecedoresSearch } from "@/api/fornecedores/fornecedores.js";
import { mapGetters } from "vuex";
export default {
  name: "ListaFornecedores",

  components: {
    DialogCategorias: () => import("./DialogCategorias.vue"),
    DialogTipos: () => import("./DialogTipos.vue"),
  },

  data() {
    return {
      loading: true,
      fornecedores: [],
      dialogCategoria: false,
      dialogTipo: false,
      search: "",
      closeOnClick: true,
      options: {
        sortBy: ["nome"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 15,
      },
      totalFornecedores: 0,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PFornecedorVisualizar() {
      return this.getAccess("Fornecedor", "visualizar");
    },
    PFornecedorAdicionar() {
      return this.getAccess("Fornecedor", "adicionar");
    },
    PCategoriaVisualizar() {
      return this.getAccess("FornecedorCategoria", "visualizar");
    },
    PTipoVisualizar() {
      return this.getAccess("FornecedorTipo", "visualizar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.nome"),
          value: "nome",
        },
        {
          text: this.$tc("global.documento"),
          value: "documento",
        },
        {
          text: this.$tc("global.endereco"),
          value: "endereco",
        },
        {
          text: this.$tc("global.cidade"),
          value: "cidade_nome",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
          value: "status",
        },
      ];
    },
  },

  watch: {
    search() {
      if (this.loading) return;
      this.buscaFornecedores();
    },

    options: {
      handler() {
        this.buscaFornecedores();
      },
      deep: true,
    },
  },

  methods: {
    buscaFornecedores() {
      const filtro = `&search=${this.search}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.getFornecedores(this.search ? filtro : "");
      }, 500);
    },

    async getFornecedores(filtro) {
      try {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const response = await getFornecedoresSearch(
          `?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}` +
            (filtro ? filtro : "")
        );

        this.fornecedores = response.data;
        this.totalFornecedores = response.total;

        if (this.options.itemsPerPage != Number(response.per_page)) {
          this.options.itemsPerPage = Number(response.per_page);
        }

        this.loading = false;
        return response;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    goToFornecedor(item) {
      if (this.PFornecedorVisualizar) {
        this.$store.commit("UPDATE_DYNAMICCRUMB", item.nome);
        this.$router.push({ path: `/fornecedores/editar/${item.id}` });
      }
    },
  },

  created() {
    this.getFornecedores();
  },
};
</script>

<style></style>
